const config = {
  keycloak: {
    clientId: 'hub-connect',
    realm: 'diga-lab-hub',
    url: 'https://sso.diga-lab-dev.apat-dtb.com/auth'
  },
  io: {
    url: 'https://medipolis.socket.api.diga-lab-dev.apat-dtb.com',
    path: '/socket.io'
  },
  jitsimeet: {
    domain: 'meet.dev.diga-lab-dev.apat-dtb.com',
    libraryName: 'external_api.min.js',
    config: {}
  },
  patientManagement: {
    url: 'https://pm-doc.diga-lab-dev.apat-dtb.com'
  },
  medicationTherapyManagement: {
    url: 'https://medication-doc.diga-lab-dev.apat-dtb.com'
  },
  therapyDiagnoses: {
    url: 'https://therapy-api.diga-lab-dev.apat-dtb.com'
  },
  dtbplaner: {
    url: 'https://therapieplaner.diga-lab-dev.apat-dtb.com'
  },
  process: {
    url: 'https://process-api.diga-lab-dev.apat-dtb.com'
  },
  pusher: {
    channels: {
      appId: '966073',
      key: '1f670dfc44fb6ca41558',
      cluster: 'eu'
    }
  },
  meet: {
    url: 'https://meet-staging.diga-lab-dev.apat-dtb.com'
  }
};

export default config;
